import meetingConfig from 'meetingConfig';
import { makeLogger } from '../logger';

const tpEnabled = () => {
  return (
    typeof ZoomTPModule !== 'undefined' &&
    ZoomTPModule.isEnableTP(meetingConfig.mediaFeatureOptions)
  );
};

export const makeTPSocket = (url) => {
  if (tpEnabled()) {
    return new ZoomTPModule.ZoomTPWebSocket(url);
  } else {
    return new WebSocket(url);
  }
};

export const initTP = () => {
  if (tpEnabled()) {
    return ZoomTPModule?.initTPModule({
      logInstance: makeLogger(['TP MODULE']),
      options: meetingConfig.mediaFeatureOptions,
    });
  }
};

export const isTPEnabledByOP = () => {
  const op = meetingConfig.mediaFeatureOptions ?? '';
  const index = op.length - 47;
  return index >= 0 && op[index] === '1';
};
