/**
 * created by xiaoyu on 2018/5/16.
 */

// Chat Priviledge
export const CHAT_PRIVILEDGE_ALL = 1; // everyone and anyone directly
export const CHAT_PRIVILEDGE_ALL_PANELIST = 2; // host and panelist
export const CHAT_PRIVILEDGE_HOST = 3; // host and co-host
export const CHAT_PRIVILEDGE_NOONE = 4; // no one
export const CHAT_PRIVILEDGE_EVERYONE_PUBLICLY = 5; // every one
export const CHAT_SEND_MSG = 0;
export const CHAT_RECEIVE_MSG = 1;

/**
 * set priviledge to rwg
 * webclient -> RWG
 */
export const PANELIST_CHAT_PRIVILEGE_REQ = {
  ALL_PANELIST: 11,
  EVERYONE: 12,
};
/**
 * RWG -> webclient 7938
 */
export const PANELIST_CHAT_PRIVILEGE = {
  ALL_PANELIST: 1,
  EVERYONE: 2,
  INVALID: 0,
};

// Nonverbal feedback
export const NONVERBAL_NONE = 0;
export const NONVERBAL_HAND = 1;
export const NONVERBAL_YES = 2;
export const NONVERBAL_NO = 3;
export const NONVERBAL_FAST = 4;
export const NONVERBAL_SLOW = 5;
export const NONVERBAL_BAD = 6;
export const NONVERBAL_GOOD = 7;
export const NONVERBAL_CLAP = 8;
export const NONVERBAL_COFFEE = 9;
export const NONVERBAL_CLOCK = 10;
export const NONVERBAL_EMOJI = 11;

// Suspension window type
// export const VIDEO_TYPE_NO_VIDEO = -1;
export const VIDEO_TYPE_90P = 0;
export const VIDEO_TYPE_180P = 1;
export const VIDEO_TYPE_360P = 2;
export const VIDEO_TYPE_720P = 3;

// MuteVideoModal type
export const MUTE_VIDEO_MODAL_START = 1;
export const MUTE_VIDEO_MODAL_STOP = 2;

// user status
export const USER_STATUS_FAILOVER = 1;

// chat response result type
export const CHAT_RESPONSE = {
  BLOCK_BY_DLP: 3, //  response the DLP checking status from RWG
  DELETE_MESSAGE: 1,
  CHAT_MESSAGE_OK: 0,
};

export const MODAL_BANNER_TIMEOUT = 5000;

export const MICROPHONE_FORBIDDEN_ERROR = {
  DEFAULT: 'Permission denied',
  BY_SYSTEM: 'Permission denied by system',
};

export const FOOTER_HEIGHT = 52;
export const HEADER_HEIGHT = 48;

export const MEDIA_NOTIFT_UI_SHOW_NOTIFICATION_ERROR_CODE = {
  PORT_BLOCKED: 1,
  LOW_RESOURCE_ERROR: 2,
  LIMITED_RESOURCE_ERROR: 3,
  FATAL_RESROUCE_ERROR: 4,
};

export const AUDIO_STREAM_FAILED_ERROR_CODE = {
  EXCEPTION: -1,
  PERMISSION_RESET: -2,
};

export const VIDEO_STREAM_FAILED_ERROR_CODE = {
  EXCEPTION: -1,
  PERMISSION_RESET: -2,
};

export const MEDIA_HEALTH_CHECK_FAILED_ERROR_CODE = {
  SHARING: -129,
  VIDEO: -130,
  AUDIO: -131,
};
