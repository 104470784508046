import { DEVICE_CHANGE_EVENT } from '../../constants/AVNotifyAPPTypes';
import deviceManager from '../../device-manager';
import eb from '../../global/event-bus';

function initDeviceManagerJob() {
  deviceManager.init();
  eb.on('media-sdk-event', ([type]) => {
    if (type === DEVICE_CHANGE_EVENT) {
      deviceManager.emit('HANDLE_DEVICE_CHANGED');
    }
  });
  return;
}

export const taskInitDeviceManager = [initDeviceManagerJob];
