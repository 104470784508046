import _ from 'lodash';
import meetingConfig from 'meetingConfig';
import ConnectService from '@zoom/connect-service';
import {
  PING_RWC_DOMAIN_EMPTY,
  PING_RWC_FAIL,
} from '../../constants/ExeternalControllerErrorTypes';
import axios from 'axios';
import { getMeetingTokens } from './meetingToken';
import { easyStore, storeType } from '../easy-store';
import { MEMORYSTORAGE_KEYS } from '../constant';
import { getJoinMeetingLog } from '../util';
import { makeLogger, reportToGlobalTracing } from '../logger';
import {
  PERFORMANCE_MARK,
  performanceCancel,
  performanceMark,
} from '../../performance';
import { getRWCOption } from './webrtc-service';
const logger = makeLogger(['Join Meeting Flow']);

function constructRWCUrl(rwcBaseUrl, boConfId) {
  const meetingTokens = getMeetingTokens();
  const { ts, auth, tid } = meetingTokens;
  const url = `https://${rwcBaseUrl}/wc/ping/${
    meetingConfig.meetingNumber
  }?ts=${ts}&auth=${encodeURIComponent(auth)}&tid=${encodeURIComponent(
    tid,
  )}&option=${getRWCOption()}`;
  if (boConfId != null) {
    return `${url}&confID=${boConfId}`;
  }
  return url;
}

const isEnableGeoFenceRWC = () =>
  meetingConfig?.meetingOptions?.isEnableGeoFenceRWC;

export const getPingRWCUrls = (boConfId) => {
  const meetingTokens = getMeetingTokens();
  const { encryptedGEORWC, encryptedRWC } = meetingTokens;
  if (isEnableGeoFenceRWC()) {
    if (!_.isEmpty(encryptedGEORWC)) {
      return encryptedGEORWC.map((rwc) => ({
        url: `${constructRWCUrl(rwc.rwc, boConfId)}&rwcToken=${
          rwc.rwcToken
        }&dmz=1`,
        domain: rwc.rwc,
        rwcToken: rwc.rwcToken,
        dl: rwc.dl,
        dc: rwc.dc,
      }));
    }
  } else {
    if (!_.isEmpty(encryptedRWC)) {
      return Object.keys(encryptedRWC).map((domain) => ({
        url: `${constructRWCUrl(domain, boConfId)}&rwcToken=${
          encryptedRWC[domain]
        }&dmz=1`,
        domain: domain,
        rwcToken: encryptedRWC[domain],
      }));
    }
  }
  let log = isEnableGeoFenceRWC
    ? JSON.stringify({ encryptedGEORWC })
    : JSON.stringify({ encryptedRWC });
  logger.log(getJoinMeetingLog(log), [PING_RWC_DOMAIN_EMPTY]);
  return [];
};

function doPingPWC(boConfId) {
  return new Promise((resolve, reject) => {
    const isInPreview = typeof boConfId === 'undefined';
    const pingRWCUrls = getPingRWCUrls(boConfId);
    if (_.isEmpty(pingRWCUrls)) {
      return reject({ reason: PING_RWC_DOMAIN_EMPTY });
    }
    const markStart = isInPreview
      ? PERFORMANCE_MARK.inPreview_RWC_start
      : PERFORMANCE_MARK.inMeeting_RWC_start;

    const markStop = isInPreview
      ? PERFORMANCE_MARK.inPreview_RWC_end
      : PERFORMANCE_MARK.inMeeting_RWC_end;
    performanceMark(markStart);
    if (isEnableGeoFenceRWC()) {
      ConnectService.ping(pingRWCUrls)
        .then(({ response, request, log }) => {
          let data = {};
          if (response) {
            data = { ...response, rwc: request.domain };
          } else {
            data = {
              rwg: request.domain,
              rwcToken: request.rwcToken,
            };
          }
          let logs = getJoinMeetingLog(log);
          logger.log(logs, ['PING_RWC_SUCCESS']).then(() => {
            reportToGlobalTracing({ filter: ['PING_RWC_SUCCESS'] });
          });
          easyStore.easySet(
            MEMORYSTORAGE_KEYS.rwcGeoFenceResponse,
            { data, log },
            storeType.memory,
          );
          performanceMark(markStop, { detail: request.domain });
          return resolve({ data, log });
        })
        .catch((error) => {
          let errors = getJoinMeetingLog(error);
          logger.log(errors, ['PING_RWC_FAILED']).then(() => {
            reportToGlobalTracing({ filter: ['PING_RWC_FAILED'] });
          });
          performanceCancel(markStart);
          return reject({ reason: PING_RWC_FAIL, error });
        });
    } else {
      let failCount = 0;
      pingRWCUrls.forEach((rwc) => {
        axios
          .get(rwc.url)
          .then(({ data }) => {
            let res = {};
            if (data) {
              res = { ...data };
            } else {
              res = {
                rwg: rwc.domain,
                rwcToken: rwc.rwcToken,
              };
            }
            appendRWCResponse(res);
            performanceMark(markStop);
            return resolve({ data: res });
          })
          .catch((error) => {
            failCount += 1;
            performanceCancel(markStart);
            if (failCount === pingRWCUrls.length) {
              return reject({ reason: PING_RWC_FAIL, error });
            }
          });
      });
    }
  });
}

let promise = null;
export function pingRWC(isReconnect, boConfId) {
  if (isReconnect) {
    promise = doPingPWC(boConfId);
    return promise;
  } else {
    if (!promise) {
      promise = doPingPWC(boConfId);
    }
    return promise;
  }
}

export function clearPingRWC() {
  promise = null;
}

export const getRWCResponse = () => {
  const valInStore = easyStore.easyGet(
    MEMORYSTORAGE_KEYS.webclient_rwc_response,
  );
  if (valInStore == null) {
    return [];
  } else {
    return valInStore;
  }
};
export const appendRWCResponse = (rwcResponse) => {
  const arr = getRWCResponse();
  if (arr.findIndex((item) => item.rwg === rwcResponse.rwg) === -1) {
    arr.push(rwcResponse);
    easyStore.easySet(
      MEMORYSTORAGE_KEYS.webclient_rwc_response,
      arr,
      storeType.memory,
    );
  }
};
