import meetingConfig from 'meetingConfig';
import { LOCALSTORAGE_KEYS } from '../constant';
import { easyStore, storeType } from '../easy-store';
import { isShowUseAudioBridgeStrategySelector } from '../op-feature-option';
import { USE_AUDIO_BRIDGE_STRATEGY } from '../../features/settings/constant';

export const audioBridgeCheck = () => {
  const previousAudioBridge = easyStore.easyGet('previousAudioBridge');
  const currentAudioBridge = isAudioBridge();
  easyStore.easySet(
    'previousAudioBridge',
    currentAudioBridge,
    storeType.memory,
  );
  const audioBridgeToWasm = !!previousAudioBridge && !currentAudioBridge;
  return { currentAudioBridge, audioBridgeToWasm };
};

const isAppleMobileDevice = () => {
  // ipad iphone and safari on ipad os
  return (
    /iPad|iPhone|iPod/i.test(navigator.userAgent) ||
    (/Macintosh/i.test(navigator.userAgent) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2)
  );
};
const isTeslaMode = () => {
  return /TESLA/.test(navigator.userAgent);
};
export const isAndroidDevice = () => navigator.userAgent.match(/Android/i);
export const isMTRAndroid = () => {
  return /MSFT Teams Android Room/.test(navigator.userAgent);
};

export const shouldShowUseAudioBridgeSelector = () => {
  if (easyStore.easyGet('audioBridgeNotWork_shouldFailOverToWASM')) {
    return false;
  }

  if (!bizAllowAudioBridge()) {
    return false;
  }

  if (
    isMTRAndroid() ||
    isTeslaMode() ||
    isAndroidDevice() ||
    isAppleMobileDevice()
  ) {
    return false;
  }

  return isShowUseAudioBridgeStrategySelector();
};

/** if you can use audioBridge */
const bizAllowAudioBridge = () =>
  !meetingConfig.meetingInterpretationInfo?.isEnableLanguageInterpretation &&
  !meetingConfig.signInterpreterInfo?.isEnable;

export const getDefaultUseAudioBridgeStrategy = () => {
  if (!shouldShowUseAudioBridgeSelector()) {
    return USE_AUDIO_BRIDGE_STRATEGY.AUTO;
  } else {
    return (
      easyStore.easyGet(LOCALSTORAGE_KEYS.use_audio_bridge_strategy) ??
      USE_AUDIO_BRIDGE_STRATEGY.AUTO
    );
  }
};

export const getFinalUseAudioBridgeStrategy = (value) => {
  if (value === USE_AUDIO_BRIDGE_STRATEGY.AUTO) {
    return undefined;
  }

  return value;
};

const evaluateAudioStrategy = _.memoize((audioBridgeStrategy) => {
  return !!JsMediaSDK_Instance?.util?.evaluateAudioStrategy?.(
    meetingConfig.mediaFeatureOptions,
    getFinalUseAudioBridgeStrategy(audioBridgeStrategy),
  );
});

// if the case is out of control by meetingConfig.webclientFeatureOptions, check ifBusinessAllowAudioBridge
export const isAudioBridge = () => {
  if (easyStore.easyGet('audioBridgeNotWork_shouldFailOverToWASM')) {
    return false;
  } else if (
    isMTRAndroid() ||
    isTeslaMode() ||
    isAndroidDevice() ||
    isAppleMobileDevice() ||
    bizAllowAudioBridge()
  ) {
    const audioBridgeStrategy = getDefaultUseAudioBridgeStrategy();
    return evaluateAudioStrategy(audioBridgeStrategy);
  }
  return false;
};
