import meetingConfig from 'meetingConfig';
import { isSinglePageFlowEnabled } from '../../global/service';
import { LoadJs } from '../global/task-utils/load-js';
import { isWebinar } from '../../global/service/meeting-types';
import { getMediasdkBaseUrl } from '../global/task-utils/common-utils';
import { isTPEnabledByOP } from '../../global/service/tp-service';

function loadScriptOnWebClientStart() {
  return LoadJs.start(meetingConfig)
    .then((v) => {
      const collection = [];
      if (
        !isSinglePageFlowEnabled() &&
        (meetingConfig.meetingOptions.isPollingEnabled || isWebinar())
      ) {
        collection.push(v.add('js/lib/webim.min.js'));
      }

      collection.push(v.add('js/icons.webclient.min.js'));
      return Promise.all(collection);
    })
    .catch(console.error); // eslint-disable-line no-console
}

function loadMeidiasdkScriptOnWebClientStart() {
  return LoadJs.start(meetingConfig)
    .then((v) => {
      const collection = [];
      collection.push(v.add(getMediasdkBaseUrl(), 'js_media.min.js'));
      if (isTPEnabledByOP()) {
        collection.push(v.add(getMediasdkBaseUrl(), 'tp.min.js'));
      }
      return Promise.allSettled(collection);
    })
    .catch(console.error); // eslint-disable-line no-console
}

function initMediasdkAsyncJob() {
  if (typeof JsMediaSDK_Instance !== 'undefined') {
    return JsMediaSDK_Instance.util?.initAsyncJob?.();
  } else {
    // eslint-disable-next-line no-console
    console.error(
      'Can not get JsMediaSDK_Instance to check  initMediasdkAsyncJob!!',
    );
  }
}

export const taskPreLoadWebClient = [
  loadScriptOnWebClientStart,
  loadMeidiasdkScriptOnWebClientStart,
  initMediasdkAsyncJob,
];
