import { easyStore } from '../../global/easy-store';
import { getMeetingTokens } from '../../global/service/meetingToken';
import { fetchProfile } from '../../global/service/profile';
import { getBaseUrl } from '../../tasks/global/task-utils/common-utils';
import {
  CDN_BACKGROUND_IMAGE,
  FILE_SERVER_TYPE,
  PUBLIC_LOACL_CUSTOM_IMAGE,
  cdnBackgroundlist,
} from '../settings/enum';
import {
  formatCloudImageList,
  formatLocalImageList,
  getAllLocalImage,
  initResourceManager,
} from '../settings/service';
import meetingConfig from 'meetingConfig';
import { BG_IMG_ID } from './consts';

export const loadBackgroundList = () => {
  return Promise.all([
    fetchCloudBackgroundList(),
    fetchLocalBackgroundList(),
  ]).then(([cloudList, localList]) => {
    return Promise.resolve([...cloudList, ...localList]);
  });
};

export const fetchCloudBackgroundList = () => {
  const { isLogin } = meetingConfig;
  if (!isLogin) {
    return cdnBackgroundlist
      .filter((item) => item.id !== 'blur')
      .map((item) => ({
        ...item,
        isCDN: true,
      }));
  }
  return fetchProfile().then((profile) => {
    try {
      const data = JSON.parse(profile);
      const vbInfo = JSON.parse(data.vbInfo);
      const vbList = JSON.parse(vbInfo.vbList);
      return formatCloudImageList(vbList);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('parse profile failed', e);
      return [];
    }
  });
};

export const fetchLocalBackgroundList = () => {
  const { isLogin } = meetingConfig;
  const nameSpaceId = isLogin ? meetingConfig.uid : PUBLIC_LOACL_CUSTOM_IMAGE;
  return getAllLocalImage(nameSpaceId).then((res) => {
    return formatLocalImageList(res);
  });
};

export const prepareBg = (backgroundIdx) => {
  if (backgroundIdx === 'blur') {
    return Promise.resolve(backgroundIdx);
  }
  return loadBackgroundList()
    .then((list) => {
      return list.find((item) => item.id === backgroundIdx);
    })
    .then((imageObject) => {
      return fetchBgImage(imageObject);
    })
    .then((imageData) => {
      const objectURL = URL.createObjectURL(imageData);
      return prepareImgDOM(objectURL);
    })
    .catch(() => {
      return null;
    });
};

export const getBackgroundConfigFromStorage = () => {
  const uid = meetingConfig.uid;
  const bgKey = uid ? `background${uid}` : 'backgroundLocalDefault';
  const bgRecord = easyStore.easyGet(bgKey) || {};
  const { backgroundIdx, backgroundName } = bgRecord;
  return {
    backgroundIdx: backgroundIdx,
    backgroundName: backgroundName,
  };
};

export const prepareImgDOM = (imgSource, domId = BG_IMG_ID) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(domId)) {
      document.body.removeChild(document.getElementById(domId));
    }
    const img = new Image(320, 100);
    img.id = domId;
    img.src = imgSource;
    document.body.appendChild(img);
    img.style.display = 'none';
    img.onload = () => {
      resolve(img.id);
    };
    img.onerror = () => {
      reject('img load failed.');
    };
  });
};

export const fetchBgImage = (imageObject) => {
  let namespaceId = '',
    path = '',
    url = '';
  let headers = {},
    body = {},
    options = {};
  if (imageObject.isCDN) {
    url = `${getBaseUrl()}/image/${imageObject.fileName}`;
    path = `${CDN_BACKGROUND_IMAGE}/${imageObject.fileName}`;
    body = {
      type: 'blob',
      fileNae: imageObject.fileName,
    };
  } else {
    path = imageObject.previewBig;
    if (imageObject.fsType === FILE_SERVER_TYPE.FILE_SERVER) {
      const { fileReadZak, zak } = getMeetingTokens();
      headers.zak =
        imageObject.isAdminUpload || imageObject.isDef ? fileReadZak : zak;
    }
    if (imageObject.isLocal) {
      namespaceId = meetingConfig.uid;
    }
  }

  return initResourceManager(namespaceId).then((resourceManager) => {
    if (!resourceManager.store.indexedDBStore.isEnable()) {
      options.forceSpecifyStore = 'memoryStore';
    }
    return resourceManager
      .getFile(
        path,
        {
          saveRootPath: path,
          downloadOptions: {
            xhrParams: {
              headers: headers,
              url: url,
              encoding: 1,
              method: 'GET',
              body,
              timeout: 60 * 1000,
            },
          },
        },
        options,
      )
      .getResult()
      .then((res) => res.data);
  });
};

export const isSupportBgInPreview = () => {
  const { isLogin, uid } = meetingConfig;
  // do not support bg in preview if uid not provided when login,
  // otherwise bg in preview will differ from meeting
  if (isLogin && !uid) {
    return false;
  } else {
    return true;
  }
};
