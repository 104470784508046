import {
  use_webRTC_strategy_error_broswer_not_support,
  use_webRTC_strategy_error_device_on_blacklist,
  use_webRTC_strategy_error_invalid_args,
  use_webRTC_strategy_error_invalid_device_info,
  use_webRTC_strategy_error_unknown,
  use_webRTC_strategy_error_unknown_selection,
  use_webRTC_strategy_other_ex,
} from './resource';

export const cdnBackgroundlist = [
  {
    id: 'blur',
    displayName: 'blur',
    fileName: 'blur.png',
  },
  {
    id: 'background0',
    displayName: 'SanFrancisco',
    fileName: 'SanFrancisco.jpg',
  },
  {
    id: 'background1',
    displayName: 'grass',
    fileName: 'grass.jpg',
  },
  {
    id: 'background2',
    displayName: 'earth',
    fileName: 'earth.jpg',
  },
];

export const FILE_TYPE = {
  DEFAULT: 0,
  CUSTOM: 1,
  VIDEO_DEFAULT: 2,
};

export const FILE_SERVER_TYPE = {
  CDN: 1,
  FILE_SERVER: 0,
};

export const MARCH_KEY = '@@@';
export const GET_START_REG = new RegExp(`(\\S*)${MARCH_KEY}`);
export const GET_END_REG = new RegExp(`${MARCH_KEY}(\\S*)`);

export const SETTINGS_CANVAS = {
  BACKGROUND_VB_CANVAS_ID: 'bgVirtualbCanvas',
};

export const VB_INIT_TYPE = {
  INIT: 0,
  THREE: 1,
  TEN: 2,
  FAIL: 3,
};

export const PUBLIC_LOACL_CUSTOM_IMAGE = 'PUBLIC_LOCAL_CUSTOM_IMAGE';

export const CDN_BACKGROUND_IMAGE = 'background';

export const REASON_TO_THIRD_PART = {
  SUCCESS: 1, // success
  FAIL_IMAGE_DOM: 2, // can not get img dom
  FAIL_VB_RECOVER: 3, // vb recover fail
  FAIL_UNKNOWN_ERROR: 4, // unknown error
  FAIL_ENABLE_VB_OFF: 5, // can not set none because enable vb off
  FAIL_GENERATE_IMAGE_KEY: 6, //cannot generate key
  FAIL_NOT_SUPPORT_VB: '10012', // not support vb
  FAIL_NOT_VB_MODE: 8, // current mode is not vb
  FAIL_VIDEO_ENCODE_NOT_READY: 9, // video encode not ready
  FAIL_VIDEO_NOT_OPEN: '10020', // video not open
  FAIL_NOT_LOGIN: 11, // need to login
  FAIL_BLUR_CANCEL: '10017', //remove blur cancel
  FAIL_FORCE_VB_ON: 13, //enableForceUseVB
  FAIL_NONE_CANCEL: '10017', //none cancel
};

export const useWebRTCStrategyErrorNo = {
  SUCCEED: 0,
  UNKNOWN: -1,
  INVALID_ARG: -2,
  BROWSER_NOT_SPT: -3,
  DEVICE_ON_BLACKLIST: -4,
  INVAILD_DEVICE_INFO: -5,
  UNKOWN_SELECTION: -6,
  OTHER_EX: -7,
  DEVICE_NOT_ON_WHITELIST: -8,
};

export const useWebRTCStrategyErrorMessage = {
  [useWebRTCStrategyErrorNo.UNKNOWN]: use_webRTC_strategy_error_unknown,
  [useWebRTCStrategyErrorNo.INVALID_ARG]:
    use_webRTC_strategy_error_invalid_args,
  [useWebRTCStrategyErrorNo.BROWSER_NOT_SPT]:
    use_webRTC_strategy_error_broswer_not_support,
  [useWebRTCStrategyErrorNo.DEVICE_ON_BLACKLIST]:
    use_webRTC_strategy_error_device_on_blacklist,
  [useWebRTCStrategyErrorNo.INVAILD_DEVICE_INFO]:
    use_webRTC_strategy_error_invalid_device_info,
  [useWebRTCStrategyErrorNo.UNKOWN_SELECTION]:
    use_webRTC_strategy_error_unknown_selection,
  [useWebRTCStrategyErrorNo.OTHER_EX]: use_webRTC_strategy_other_ex,
};
