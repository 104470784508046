/* eslint-disable no-console */
/* eslint no-restricted-imports: ["error", { paths: [{
    name: "./service",
    message: "Please avoid import 'global/service' methods in this file"
}]}] */
/* eslint-disable @babel/new-cap */
import _ from 'lodash';
import React from 'react';
import langResource from 'langResource';
import cookie from 'react-cookie';
import { Base64 } from 'js-base64';
import classNames from 'classnames';
import URLSafeBase64 from 'urlsafe-base64';
import meetingConfig from 'meetingConfig';
import { I18nLoader } from './load-apac-resource';
import {
  CLIENT_SUPPORT_SWITCH_REMOTE_CAMERA,
  MAX_CONCURRENCY_VIDEO_THREADS,
  WEBINAR_CLIENT_CAP_CAN_MUTE,
  WEBINAR_CLIENT_CAP_SUPPORT_FETCH_MEETING_TOKEN,
  WEBNIAR_CLIENT_CAP_SUPPORT_CONF_CHAT_CHANNEL,
  WEBNIAR_CLIENT_CAP_SUPPORT_FILE_TRANSFER,
  supportedBrowserVersions,
} from './constant';
import { CONTROL_MODE_CONNECTORS_ENUM } from '../controller/enum';
import { RWG_USER_TYPE } from './enum';
import qs from 'qs';
import { PWAExist } from './pwa-integration';
import { isEnableZoomRemoteControl } from './op-feature-option';
import emojiRegex from 'emoji-regex';
import { getUA } from './utils/ua';

export const stringFormat = (source, ...params) =>
  params.reduce(
    (s, val, index) => s.replace(new RegExp(`\\{${index}\\}`, 'g'), val),
    source,
  );

export const isOlderEdge = () => /edge\/(\d+)/i.test(navigator.userAgent);

export const isOpera = () => {
  const name = getUA()?.browser?.name ?? '';
  return name.toLowerCase().includes('opera');
};

export const isFirefox = () => {
  const name = getUA()?.browser?.name ?? '';
  return name.toLowerCase().includes('firefox');
};
export const isMacOS = () => /mac/i.test(navigator.platform);
export const isNewEdge = () => /edg\/(\d+)/i.test(navigator.userAgent);

export function isMac() {
  return navigator.platform.indexOf('Mac') > -1;
}

export const isChrome = () => {
  const name = getUA()?.browser?.name ?? '';
  return name.toLowerCase().includes('chrom');
};

export function isSupportAV() {
  return (
    meetingConfig.isSupportAV &&
    typeof WebAssembly === 'object' &&
    typeof Worker === 'function' &&
    typeof AudioWorklet === 'function'
  );
}

export const isWindows = () => {
  const { userAgent } = navigator;
  return /windows/i.test(userAgent);
};

export const isWindowsChrome = () => {
  const { userAgent } = navigator;
  return isChrome() && /windows/i.test(userAgent);
};

export const getChromeVersion = () => {
  const version = getUA()?.browser?.version ?? '';
  const [major, minor] = version.split('.');
  return parseInt(`${major}.${minor}`, 10);
};

export const getBrowserShortVersion = () => {
  const version = getUA()?.browser?.version ?? '';
  const [major, minor] = version.split('.');
  return Number(`${major}.${minor}`);
};

export const getSafariVersion = () => {
  const raw = navigator.userAgent.match(/Version\/([\d.]+).*Safari/);
  if (!raw) return 0;
  return parseFloat(raw[1], 10);
};

export const isChromeOS = () => {
  return /\bCrOS\b/.test(navigator.userAgent);
};

export const isOculus = () => {
  return /oculusbrowser/i.test(navigator.userAgent);
};

export const isGoogleMeetMode = () => {
  return meetingConfig?.partnerId === 'GOOGLE-MEET';
};

export const isStandardIntegration = () => {
  return isGoogleMeetMode();
};

let vendorId;
export const isMSFTMode = () => {
  const controller = window.meetingHost;
  if (!controller) return false;
  if (!vendorId) {
    vendorId = controller.getHostState().vendorId;
  }
  if (vendorId !== CONTROL_MODE_CONNECTORS_ENUM.MSFT) {
    return false;
  }
  return true;
};
export const isMTRAndroid = () => {
  return /MSFT Teams Android Room/.test(navigator.userAgent);
};
export const isYealink = () => {
  return /yealink/.test(navigator.userAgent.toLowerCase());
};
export const isTeslaMode = () => {
  return /TESLA/.test(navigator.userAgent);
};

export const getDeviceType = () => {
  if (isGoogleMeetMode()) {
    return 'GMH';
  } else if (isMTRAndroid()) {
    return 'MTR-A';
  } else if (isMSFTMode() && !isMTRAndroid()) {
    return 'MTR-W';
  } else if (isTeslaMode()) {
    return 'Tesla';
  } else if (isMobileDevice()) {
    return 'Mobile';
  } else {
    return 'Desktop';
  }
};

export const isExternalControlledMode = () => {
  return isMSFTMode() || isTeslaMode() || isMTRAndroid() || isGoogleMeetMode();
};

export const externalStatus = {
  isHangup: false,
};

export const isSafari = () => {
  const name = getUA()?.browser?.name ?? '';
  return name.toLowerCase().includes('safari');
};

export const isArm = () => {
  return /arm/i.test(navigator.userAgent);
};

export const isArmChromeOS = () => isChromeOS() && isArm();

export const isSupportAudioWorklet = () => typeof AudioWorklet === 'function';

export const isDeviceLessPerformant = () => false;

export function isRwgPhoneUser(user) {
  return user && user.userType === RWG_USER_TYPE.PHONE;
}

const isIPad = () => {
  return (
    /iPad/i.test(navigator.userAgent) ||
    (/Macintosh/i.test(navigator.userAgent) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2)
  );
};

export const isSafariOnIOS = () => {
  const ua = navigator.userAgent;
  const iOS =
    !!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Macintosh/);

  const webkit = !!ua.match(/WebKit/i);
  const mobile = !!ua.match(/Mobile/i);
  const ipad = isIPad();
  return iOS && webkit && (mobile || ipad);
};

export const isMobileDevice = () =>
  navigator.userAgent.match(/Android/i) || // tablet, phone, mrt-a
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  isIPad() ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i);

export const isAndroidDevice = () => navigator.userAgent.match(/Android/i);

export const isOpenHarmonyDevice = () =>
  navigator.userAgent.match(/\(([^;]+); OpenHarmony ([\d.]+)\)/);

export const getNextA11yLevel = (base, increment) => {
  if (!base) return null;
  const baseArr = base.split('-');
  const newBase =
    +baseArr[baseArr.length - 1] + (increment === undefined ? 1 : increment);
  baseArr[baseArr.length - 1] = newBase;
  return baseArr.join('-');
};

export const performSafeFocus = (ele) => {
  if (!ele) return;
  setTimeout(() => {
    ele.focus();
  }, 0);
};

const escapeHtml = (str) => {
  const div = document.createElement('div');
  div.appendChild(document.createTextNode(str));
  return div.innerHTML;
};

export const ariaNotifier = document.getElementById('aria-notify-area');
export const promptA11yInfo = (str) => {
  ariaNotifier.innerHTML = escapeHtml(str);
};

export const lastItem = (list) => list[list.length - 1];

export const isNone = (...arg) =>
  arg.every((v) => v === null || v === undefined || v === '');

export const getSelectedClass = (flag) => {
  let selectClass;
  if (flag) {
    selectClass = classNames('i-ok-margin');
  } else {
    selectClass = classNames('i-margin');
  }
  return selectClass;
};

export const isSupportIndexDB = (() => {
  return new Promise((resolve, reject) => {
    if (typeof indexedDB === 'undefined' && indexedDB == null) {
      reject(new Error({ code: -1, message: 'can not find indexdb instance' }));
    }
    const req = window.indexedDB.open('test');
    req.onerror = (err) => {
      if (err?.target?.error?.code === 11) {
        reject(err.target.error);
      }
      resolve('success');
    };
    req.onsuccess = () => {
      resolve('success');
    };
  });
})();

export const waitGetIndexDBVersion = (dbName) =>
  new Promise((resolve) => {
    if (!window.indexedDB) {
      resolve('');
    }
    const req = window.indexedDB.open(dbName);
    req.onerror = () => resolve('');
    req.onsuccess = () => {
      resolve(req.result.version);
    };
  });

export function toHalfCode(tmpStr) {
  let tmp = '';
  for (let i = 0; i < tmpStr.length; i++) {
    if (tmpStr.charCodeAt(i) === 12288) {
      tmp += String.fromCharCode(tmpStr.charCodeAt(i) - 12256);

      continue;
    }
    if (tmpStr.charCodeAt(i) > 65280 && tmpStr.charCodeAt(i) < 65375) {
      tmp += String.fromCharCode(tmpStr.charCodeAt(i) - 65248);
    } else {
      tmp += String.fromCharCode(tmpStr.charCodeAt(i));
    }
  }
  return tmp;
}

export function getFullOrHalfNum(tmpStr) {
  let tmp = '';
  for (let i = 0; i < tmpStr.length; i++) {
    if (tmpStr.charCodeAt(i) >= 65296 && tmpStr.charCodeAt(i) <= 65305) {
      tmp += String.fromCharCode(tmpStr.charCodeAt(i));
    } else if (tmpStr.charCodeAt(i) >= 48 && tmpStr.charCodeAt(i) <= 57) {
      tmp += String.fromCharCode(tmpStr.charCodeAt(i));
    }
  }
  return tmp;
}

export function encodeUnsafeBase64(value) {
  return Base64.encode(value, false);
}
export function decodeUnsafeBase64(value) {
  return Base64.decode(value, false);
}

/* url safe mode of base64 is required for any system-wide data encoding/decoding */
export function encodeBase64(value) {
  return Base64.encode(value, true);
}
/* url safe mode of base64 is required for any system-wide data encoding/decoding */
export function decodeBase64(value) {
  return Base64.decode(value, true);
}

export function decodeBase64ToBuffer(value) {
  return URLSafeBase64.decode(value);
}

window.decodeBase64 = decodeBase64;
window.encodeBase64 = encodeBase64;
window.encodeUnsafeBase64 = encodeUnsafeBase64;
window.decodeUnsafeBase64 = decodeUnsafeBase64;

export function truncateString(str, { length }) {
  // eslint-disable-next-line
  const r = /[^\x00-\xff]/g;
  if (str.replace(r, '**').length <= length) {
    return str;
  }
  const m = Math.floor(length / 2);
  for (let i = m; i < str.length; i++) {
    if (str.substr(0, i).replace(r, '**').length >= length) {
      return `${str.substr(0, i)}...`;
    }
  }
  return str;
}

const HEX = 16;

export const getCharLength = (char) => {
  const charCode = char.charCodeAt(0);
  if (charCode <= parseInt('0x7F', HEX)) {
    return 1;
  }
  if (charCode <= parseInt('0x7FF', HEX)) {
    return 2;
  }
  if (charCode <= parseInt('0xFFFF', HEX)) {
    return 3;
  }
  if (charCode <= parseInt('0x1FFFFF', HEX)) {
    return 4;
  }
  if (charCode <= parseInt('0x3FFFFFF', HEX)) {
    return 5;
  }
  return 6;
};

export const sliceStringByCodeLength = (str, codeLength) => {
  if (!str || codeLength === 0) {
    return '';
  }
  let where = 0;
  for (let i = 0; i < str.length; i++) {
    where += getCharLength(str[i]);
    if (where === codeLength) {
      return str.slice(0, i + 1);
    }
  }
  return str;
};

export function getStringLength(inputStr) {
  if (inputStr === '' || inputStr === undefined) {
    return 0;
  }
  let iLength = 0;
  for (let i = 0; i < inputStr.length; i++) {
    if (inputStr.charCodeAt(i) <= parseInt('0x7F', HEX)) {
      iLength += 1;
    } else if (inputStr.charCodeAt(i) <= parseInt('0x7FF', HEX)) {
      iLength += 2;
    } else if (inputStr.charCodeAt(i) <= parseInt('0xFFFF', HEX)) {
      iLength += 3;
    } else if (inputStr.charCodeAt(i) <= parseInt('0x1FFFFF', HEX)) {
      iLength += 4;
    } else if (inputStr.charCodeAt(i) <= parseInt('0x3FFFFFF', HEX)) {
      iLength += 5;
    } else {
      iLength += 6;
    }
  }
  return iLength;
}

export const replaceStringByCodeLength = (
  rawStr,
  strToInsert,
  startAt,
  eraseCount,
) => {
  let currentWhere = 0;
  let result = '';
  let count = eraseCount;
  let where = startAt;
  const contentLength = getStringLength(rawStr);
  if (rawStr === '') {
    return strToInsert;
  }
  if (startAt >= contentLength && count === 0) {
    return rawStr + strToInsert;
  }
  if (where === 0 && count === 0) {
    return strToInsert;
  }
  if (eraseCount === -1 || startAt + eraseCount > contentLength) {
    count = contentLength - startAt;
  }
  if (count === 0 && startAt === contentLength && startAt > 0) {
    where = startAt - 1;
  }

  for (let i = 0; i < rawStr.length; i++) {
    if (currentWhere === where) {
      result = rawStr.slice(0, i) + strToInsert;
    }
    if (currentWhere === contentLength - 1) {
      break;
    }
    if (currentWhere === where + count) {
      result += rawStr.slice(i);
      break;
    }
    currentWhere += getCharLength(rawStr[i]);
  }
  return result;
};

export const emojiRegExp = emojiRegex();

export function cutString(str, len) {
  if (!str) {
    return '';
  }
  if (str.length * 2 <= len) {
    return str;
  }
  let strlen = 0;
  let s = '';
  for (let i = 0; i < str.length; i++) {
    s += str.charAt(i);
    if (str.charCodeAt(i) > 128) {
      strlen += 2;
      if (strlen >= len) {
        return `${s.substring(0, s.length - 1)}...`;
      }
    } else {
      strlen += 1;
      if (strlen >= len) {
        return `${s.substring(0, s.length - 2)}...`;
      }
    }
  }
  return s;
}

export function saveCookie(liveTime, cookieName, cookieValue, options = {}) {
  const cookieTime = new Date();
  cookieTime.setTime(cookieTime.getTime() + liveTime);
  cookie.save(cookieName, cookieValue, {
    expires: cookieTime,
    path: '/',
    ...options,
  });
}

export function removeCookie(cookieName, options = {}) {
  cookie.remove(cookieName, { path: '/', ...options });
}

export function loadCookie(cookieName) {
  return cookie.load(cookieName);
}

const i18n = IS_LOCAL ? new I18nLoader({}) : new I18nLoader(langResource);

export function iText(defaultText, resourceKey, resourceParameter) {
  if (typeof i18n === 'undefined') {
    return defaultText || resourceKey;
  }
  const i18nText =
    typeof resourceParameter !== 'undefined'
      ? i18n.get(resourceKey, resourceParameter)
      : i18n.get(resourceKey);
  if (!!i18nText && i18nText !== resourceKey) {
    return i18nText;
  }
  return defaultText || resourceKey;
}

export function sortByDisplayName(a, b) {
  const nameA = a.displayName.toUpperCase(); // ignore upper and lowercase
  const nameB = b.displayName.toUpperCase(); // ignore upper and lowercase
  if (typeof a.zoomID === 'number' || typeof b.zoomID === 'number') {
    if (typeof a.zoomID === 'number' && typeof b.zoomID !== 'number') {
      return -1;
    }
    if (typeof a.zoomID !== 'number' && typeof b.zoomID === 'number') {
      return 1;
    }
    return a.zoomID > b.zoomID ? 1 : -1;
  }
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}
export const bytesArrayBuffer2String = (arraybuffer) => {
  let result = '';
  for (let i = 0; i < arraybuffer.length; i++) {
    result += String.fromCharCode(arraybuffer[i]);
  }
  return result;
};

export function concatUint8s(one, two) {
  one = new Uint8Array(one);
  two = new Uint8Array(two);

  const uint8s = new Uint8Array(one.length + two.length);

  uint8s.set(one);
  uint8s.set(two, one.length);

  return uint8s;
}

export function str2uint8s(str) {
  if (!str) {
    return null;
  }

  const uint8s = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    uint8s[i] = str.charCodeAt(i);
  }

  return uint8s;
}

export function utf8ArrayToStr(array) {
  let out;
  let i;
  let len;
  let c;
  let char2;
  let char3;

  out = '';
  len = array.length;
  i = 0;
  while (i < len) {
    /* eslint-disable-next-line no-plusplus */
    c = array[i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        /* eslint-disable-next-line no-plusplus */
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        /* eslint-disable-next-line no-plusplus */
        char2 = array[i++];
        /* eslint-disable-next-line no-plusplus */
        char3 = array[i++];
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0),
        );
        break;
    }
  }
  return out;
}

export function rawStringToBuffer(str) {
  let idx;
  const len = str.length;
  const arr = new Array(len);
  for (idx = 0; idx < len; ++idx) {
    arr[idx] = str.charCodeAt(idx) & 0xff;
  }
  // You may create an ArrayBuffer from a standard array (of values) as follows:
  return new Uint8Array(arr);
}

export function newSafeUrlEncode(str) {
  return btoa(unescape(encodeURIComponent(str)));
}

export function formatDate(date, fmt) {
  let result = '';
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    result = fmt.replace(
      RegExp.$1,
      `${date.getFullYear()}`.substr(4 - RegExp.$1.length),
    );
  }
  Object.keys(o).forEach((k) => {
    if (new RegExp(`(${k})`).test(result)) {
      result = result.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length),
      );
    }
  });
  return result;
}

export const moveTextCursorToEnd = (input, endIndex) => {
  if (input.setSelectionRange) {
    input.focus();
    input.setSelectionRange(endIndex, endIndex);
  } else if (input.createTextRange) {
    const range = input.createTextRange();
    range.moveEnd('character', endIndex);
    range.moveStart('character', endIndex);
    range.select();
  }
};

export const isSupportVideoTrackReader = () =>
  JsMediaSDK_Instance &&
  JsMediaSDK_Instance.util &&
  JsMediaSDK_Instance.util.isSupportVideoTrackReader();

export const isSupportMediaStreamTrackProcessor = () =>
  typeof MediaStreamTrackProcessor === 'function';

export const isSupportSharingTrackReader = () => {
  return isSupportVideoTrackReader();
};

export const isLowEndChromeBook = () => {
  // chromeOS x86 <=4
  if (isChromeOS() && !isArm() && navigator.hardwareConcurrency < 4) {
    return true;
  }

  if (isArmChromeOS() && navigator.hardwareConcurrency <= 4) {
    return true;
  }

  return false;
};

export const isSelfPreviewRenderWithVideo = (initVideoEncodeStatus) => {
  if (initVideoEncodeStatus !== 'success') return false;
  return (
    JsMediaSDK_Instance &&
    JsMediaSDK_Instance.util &&
    JsMediaSDK_Instance.util.isSelfPreviewRenderWithVideo &&
    JsMediaSDK_Instance.util.isSelfPreviewRenderWithVideo()
  );
};

const isEnableGallery = () => {
  const isDisableGVForLowEndDevice =
    meetingConfig.isDisableGVForLowEndDevice ?? true;
  const enableGallery = easyStore.easyGet('enableGallery') ?? true;
  return !isDisableGVForLowEndDevice && enableGallery;
};

const isEnable25VideoSize = () => {
  if (navigator.hardwareConcurrency < 4) {
    return false;
  }

  if (isTeslaMode()) {
    return false;
  }

  return easyStore.easyGet('webClient_isOpenVideo25Size') ?? true;
};

export const isSupportWebGLOffscreenCanvas = (() => {
  if (typeof OffscreenCanvas !== 'function') {
    return false;
  }

  const ofs = new OffscreenCanvas(1, 1);
  if (ofs.getContext('webgl')) {
    return true;
  }

  return false;
})();

export const isSupportMultiView = () => {
  if (isOpenHarmonyDevice()) {
    return false;
  }

  if (isAndroidDevice() && !isMTRAndroid()) {
    return false;
  }

  if (!navigator?.hardwareConcurrency) {
    return false;
  }

  if (isLowEndChromeBook()) {
    return isEnableGallery();
  }

  // 2023/5/15 safari new version support OffscreenCanvas, not support webgl
  // update new WebGLOffscreenCanvas condition
  return (
    isSupportWebGLOffscreenCanvas &&
    typeof requestAnimationFrame === 'function' &&
    typeof SharedArrayBuffer === 'function'
  );
};

export const getMaxConcurrenyVideoThread = () => {
  if (isTeslaMode()) {
    const configReg = / max-video:(\d+)/;
    const videoNumExec = configReg.exec(navigator.userAgent);
    let videoCount = 5;
    if (videoNumExec && videoNumExec[1]) {
      const configVideoCount = Number(videoNumExec[1]);
      if (
        !Number.isNaN(configVideoCount) &&
        configVideoCount > 1 &&
        configVideoCount < 10
      ) {
        videoCount = configVideoCount;
      }
    }
    return videoCount;
  }

  if (!isSupportMultiView()) {
    return MAX_CONCURRENCY_VIDEO_THREADS.SIZE_1;
  }

  if (!isEnable25VideoSize()) {
    return MAX_CONCURRENCY_VIDEO_THREADS.SIZE_10;
  }

  return MAX_CONCURRENCY_VIDEO_THREADS.SIZE_26;
};

const isNotificationAPISupported = () => {
  return 'Notification' in window;
};

export const requestBrowserNotificationPermission = () => {
  if (isTeslaMode()) return false;
  if (!isNotificationAPISupported()) return false;
  if (Notification.permission === 'granted') {
    return true;
  }
  if (Notification.permission === 'denied') {
    return false;
  }
  Notification.requestPermission();
  return false;
};

export const isSpecialArmChromeOS = () => {
  return isArmChromeOS() && navigator.hardwareConcurrency <= 4;
};

export const isSpecialChromeOS = () => {
  return isChromeOS() && !isArm() && navigator.hardwareConcurrency < 4;
};

export const getVideoDPI = () => {
  if (isSpecialChromeOS() || isSpecialArmChromeOS()) {
    return {
      width: 320,
      height: 180,
    };
  }

  if (
    JsMediaSDK_Instance?.util?.get720pcapacity &&
    JsMediaSDK_Instance.util.get720pcapacity()
  ) {
    return {
      width: 1280,
      height: 720,
    };
  }

  return {
    width: 640,
    height: 360,
  };
};

function setCaps(caps, currentCap) {
  return caps | currentCap;
}

export const getXMPPClientCap = () => {
  let caps =
    WEBINAR_CLIENT_CAP_SUPPORT_FETCH_MEETING_TOKEN |
    WEBNIAR_CLIENT_CAP_SUPPORT_CONF_CHAT_CHANNEL |
    WEBNIAR_CLIENT_CAP_SUPPORT_FILE_TRANSFER;

  if (isSupportAudioWorklet() && isSupportAV()) {
    caps = setCaps(caps, WEBINAR_CLIENT_CAP_CAN_MUTE);
  }

  return caps;
};

export const isSupportBroadcastVoice = () => {
  const isEnableBOBroadcastVoice =
    meetingConfig.meetingOptions.isEnableBOBroadcastVoice;
  if (typeof isEnableBOBroadcastVoice !== 'undefined') {
    return isEnableBOBroadcastVoice;
  }
  return true;
};

/**
 * @description media-sdk has enabled video share recv on all platform browser,
 * but send videoshare still only support chrome.
 */
export const isSupportVideoShare = () =>
  JsMediaSDK_Instance?.util?.isSupportVideoShareReceive?.() ?? false;

export const isSupportVideoShareSend = () =>
  JsMediaSDK_Instance?.util?.isSupportVideoShareSend?.() ?? false;

export const isSupportSendVideoFullHD = () =>
  JsMediaSDK_Instance?.util?.isSupportSendVideoFullHD?.() ?? false;

export const isSupportSendVideoShareFullHD = () =>
  JsMediaSDK_Instance?.util?.isSupportSendVideoShareFullHD?.() ?? false;

export const isBitSet = (data, bit) => {
  return (data & bit) === bit;
};

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const parseTimeStamp = (timeStampString) => {
  if (timeStampString.length === 10) {
    return Number(timeStampString) * 1000;
  }
  return Number(timeStampString);
};

export function getLocalTime(time, options) {
  const timestamp = time ? new Date(parseTimeStamp(time)) : new Date();
  try {
    if (options) {
      return timestamp.toLocaleTimeString(meetingConfig.lang, options);
    }
    return timestamp.toLocaleTimeString(meetingConfig.lang);
  } catch (e) {
    if (options) {
      return timestamp.toLocaleTimeString([], options);
    }
    return timestamp.toLocaleTimeString();
  }
}

export function allowAndCanTalkThroughVIOP(isAllowTalk) {
  return isAllowTalk && isSupportAudioWorklet() && isSupportAV();
}

export const checkIsSupportScrollbarWidth = () => isFirefox();
export const deepFreeze = (o) => {
  Object.freeze(o);

  Object.getOwnPropertyNames(o).forEach((prop) => {
    if (
      /* eslint-disable-next-line no-prototype-builtins */
      o.hasOwnProperty(prop) &&
      o[prop] !== null &&
      (typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
      !Object.isFrozen(o[prop])
    ) {
      deepFreeze(o[prop]);
    }
  });

  return o;
};

export function updateByKey(_target, source, keyString) {
  const notExistItems = [];
  const target = [..._target];
  source.forEach((s) => {
    const existIndex = target.findIndex((t) => t[keyString] === s[keyString]);
    if (existIndex === -1) {
      notExistItems.push(s);
    }
    target[existIndex] = { ...target[existIndex], ...s };
  });

  return target.concat(notExistItems);
}

export function removeByKey(target, source, keyString) {
  return _.differenceBy(target, source, keyString);
}

export function isSameUser(nodeId, sessionId) {
  if (!nodeId || !sessionId) {
    /* eslint-disable-next-line no-console */
    // console.error(
    //   `Invalid arguments nodeId: ${nodeId}, sessionId: ${sessionId}`,
    // );
    return false;
  }
  return nodeId >> 10 === sessionId >> 10;
}

export function splitFileName(text) {
  if (!text) {
    return '';
  }

  const point = text.lastIndexOf('.');

  if (point === -1) {
    return text;
  }

  const result = text.substring(0, point);

  return result;
}
export const makeDataALGenerator = (prefix, spliter = '-', startAt = 0) => {
  let currentL = startAt;
  return () => {
    const dataAL = `${prefix}${spliter}${currentL}`;
    currentL += 1;
    return dataAL;
  };
};

export const makeA11yListInfoInjector = (
  dataALPrefix,
  listInfoContainerRef,
  spliter = '-',
  startAt = 0,
) => {
  const tempDataALGenerator = makeDataALGenerator(
    dataALPrefix,
    spliter,
    startAt,
  );
  const { dataALGenerator, getDataALGenerateCount } = (() => {
    let dataALGenerateCount = 0;
    const dataALGenerator = () => {
      dataALGenerateCount += 1;
      return tempDataALGenerator();
    };
    return {
      getDataALGenerateCount: () => dataALGenerateCount,
      dataALGenerator,
    };
  })();
  setTimeout(() => {
    if (listInfoContainerRef?.current?.dataset) {
      listInfoContainerRef.current.dataset.aLListInfo = `${dataALPrefix}${spliter}${startAt},${dataALPrefix}${spliter}${
        (getDataALGenerateCount() || 1) - 1
      }`;
    }
  }, 0);
  return { dataALGenerator, getDataALGenerateCount };
};
export const mergeRefs = (...refs) => {
  if (refs.length === 0) return refs[0];
  return (cur) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(cur);
      } else if (ref) {
        ref.current = cur;
      }
    });
  };
};

export const checkWillChildrenRenderAnyText = (component) => {
  if (!React.isValidElement(component)) {
    if (component) return true;
    return false;
  }
  const children = component?.props?.children;
  if (!children) return false;
  if (Array.isArray(children)) {
    return children.map(checkWillChildrenRenderAnyText).some((v) => Boolean(v));
  }
  return checkWillChildrenRenderAnyText(children);
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));

    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const queryBit = (value, bit) => {
  return Boolean(value & bit);
};

export const toggleBit = (value, bit) => {
  return value ^ bit;
};
export function isVaildEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email.match(emailformat)) {
    return true;
  } else {
    return false;
  }
}

export const isShowTeslaFooterBarAndJoinDialog = () => {
  if (isTeslaMode()) {
    const configReg = / show-menu:(\d+)/;
    const showMenuExec = configReg.exec(navigator.userAgent);
    let isShow = 0;
    if (showMenuExec && showMenuExec[1]) {
      isShow = Number(showMenuExec[1]);
    }
    return isShow === 1;
  }
  return true;
};

export function checkIsGoEnv() {
  const hostname = window.location.hostname;
  const splited = hostname.split('.');
  return (
    splited[splited.length - 2] === 'zoom' &&
    splited[splited.length - 3] === 'go'
  );
}

export function checkIsProdEnv() {
  if (checkIsGoEnv()) {
    return false;
  }
  const hostname = window.location.hostname;
  const splited = hostname.split('.');
  return splited[splited.length - 2] === 'zoom';
}

export function checkIsDevEnv() {
  const hostname = window.location.hostname;
  const splited = hostname.split('.');
  return splited[splited.length - 2] === 'zoomdev';
}

export function getJoinMeetingLog(log) {
  const JOIN_MEETING_FLOW_JOIN_BO = easyStore.easyGet(
    'JOIN_MEETING_FLOW_JOIN_BO',
  );
  const JOIN_MEETING_FLOW_LEAVE_BO = easyStore.easyGet(
    'JOIN_MEETING_FLOW_LEAVE_BO',
  );
  const JOIN_MEETING_FLOW_PROMOTE = easyStore.easyGet(
    'JOIN_MEETING_FLOW_PROMOTE',
  );
  const JOIN_MEETING_FLOW_DEPROMOTE = easyStore.easyGet(
    'JOIN_MEETING_FLOW_DEPROMOTE',
  );

  let tag = '';
  tag += JOIN_MEETING_FLOW_JOIN_BO ? 'JOIN_MEETING_FLOW_JOIN_BO' : '';
  tag += JOIN_MEETING_FLOW_LEAVE_BO ? 'JOIN_MEETING_FLOW_LEAVE_BO' : '';
  tag += JOIN_MEETING_FLOW_PROMOTE ? 'JOIN_MEETING_FLOW_PROMOTE' : '';
  tag += JOIN_MEETING_FLOW_DEPROMOTE ? 'JOIN_MEETING_FLOW_DEPROMOTE' : '';

  return `${tag}\n${log}\nmid:${meetingConfig.mid}`;
}

export function pollingRequest(
  func,
  time,
  endTime,
  immediate = false,
  failFunc,
) {
  if (immediate) {
    func();
  }
  const startTime = new Date().getTime();
  const pollTimer = setInterval(() => {
    const nowTime = new Date().getTime();
    if (endTime && nowTime - startTime >= endTime) {
      if (pollTimer) {
        clearInterval(pollTimer);
      }
      if (failFunc) {
        failFunc();
      }
    }
    func();
  }, time);
  return pollTimer;
}

export const getQueryString = (name) => {
  var reg = new RegExp(name + '=([^&]*)(&|$)', 'i');
  var r = window.location.href.match(reg);
  if (r != null) return unescape(r[1]);
  return null;
};

export const getSharingPlatform = () => (isMacOS() ? 'mac' : 'win');
export const isSupportOpenMicWhenShareAudio = () =>
  JsMediaSDK_Instance &&
  JsMediaSDK_Instance.util &&
  JsMediaSDK_Instance.util.isSupportOpenMicWhenShareAudio &&
  JsMediaSDK_Instance.util.isSupportOpenMicWhenShareAudio();

const query = qs.parse(location.search.substring(1)) || {};

// eslint-disable-next-line @babel/new-cap
export const isTransferMeeting = query.transfer === '1' && PWAExist();

export const isSupportZoomRemoteControl = () =>
  isEnableZoomRemoteControl() &&
  !isSafari() &&
  !isChromeOS() &&
  !isExternalControlledMode() &&
  meetingConfig.meetingOptions.isRemoteControlEnbaled;
// isChrome() &&
// getChromeVersion() >= 107;

export const isSupportAudioBridgeAvsync = () => {
  return (
    JsMediaSDK_Instance?.util?.isSupportAudioBridgeAvsync &&
    JsMediaSDK_Instance.util.isSupportAudioBridgeAvsync()
  );
};
export function buffer2Str(buf) {
  return String.fromCharCode(...buf);
}

// some base64 string from native client is safe
/**
 *
 * @param {string} base64str
 * @returns string
 */
export function getSafeBase64(base64str) {
  return base64str.replace(/\//g, '_').replace(/\+/g, '-').replace(/=/g, '');
}
export const is2K = (() => {
  const screenWidth = window.screen?.width;
  const screenHeight = window.screen?.height;
  const pixelRatio = window.devicePixelRatio;

  if (
    screenWidth * pixelRatio >= 2560 &&
    screenHeight * pixelRatio >= 1440 &&
    screenWidth * pixelRatio < 3840
  ) {
    return true;
  }

  return false;
})();

export const is4K = (() => {
  const screenWidth = window.screen?.width;
  const screenHeight = window.screen?.height;
  const pixelRatio = window.devicePixelRatio;

  if (screenWidth * pixelRatio >= 3840 && screenHeight * pixelRatio >= 2160) {
    return true;
  }

  return false;
})();

export const doubleRaf = (callback) => {
  const raf = window.requestAnimationFrame
    ? window.requestAnimationFrame
    : window.setTimeout;

  raf(() => {
    raf(callback);
  });
};
export const raf = (callback) => {
  const raf = window.requestAnimationFrame
    ? window.requestAnimationFrame
    : window.setTimeout;

  raf(callback);
};
export const getPTZCapability = () => ({
  pan: !!JsMediaSDK_Instance.util?.isSupportCameraPan?.(),
  tilt: !!JsMediaSDK_Instance.util?.isSupportCameraTilt?.(),
  zoom: !!JsMediaSDK_Instance.util?.isSupportCameraZoom?.(),
});

export const isSupportCameraPTZ = () => {
  const { pan, tilt, zoom } = getPTZCapability();
  return pan || tilt || zoom;
};

export const isSupportFarEndCameraControl = (caps) => {
  return (
    (caps & CLIENT_SUPPORT_SWITCH_REMOTE_CAMERA) ===
    CLIENT_SUPPORT_SWITCH_REMOTE_CAMERA
  );
};

export const isEnableAutoAcceptFECCInPwa = () => {
  if (PWAExist()) {
    const pwaClientFeatureOptions =
      window.parent.PwaConfig?.pwaClientFeatureOptions;
    if (
      typeof pwaClientFeatureOptions === 'string' &&
      pwaClientFeatureOptions.length > 0
    ) {
      return pwaClientFeatureOptions.split('').reverse()[2] === '1';
    }
  }
  return false;
};

export function getExternalLinkPage() {
  return meetingConfig.externalLinkPage;
}

export function xssFilter(tmpVal) {
  let val = tmpVal;
  val = val.toString();
  val = val.replace(/</g, '&lt;');
  val = val.replace(/>/g, '&gt;');
  val = val.replace(/ /g, '&nbsp;');
  val = val.replace(/"/g, '&quot;');
  val = val.replace(/'/g, '&#39;');
  val = val.replace(/&lt;\/br&gt;/g, '</br>');
  return val;
}

export function outSideLinkWrap(linkUrl) {
  const externalLinkPage = getExternalLinkPage();
  if (!externalLinkPage) {
    return linkUrl;
  }
  return `${externalLinkPage}?ref=${encodeURIComponent(xssFilter(linkUrl))}`;
}

export function transformParams(paramsObj) {
  const keys = Object.keys(paramsObj);
  return keys.map((key) => `${key}=${paramsObj[key]}`).join('&');
}

export function calculateDistanceFromWindowTop(elementId) {
  const elem = document.getElementById(elementId);
  return elem?.getBoundingClientRect().top || 0;
}

export const getFireFoxVersion = () => {
  const raw = navigator.userAgent.match(/firefox\/([0-9]+)\./i);

  return raw ? parseInt(raw[1], 10) : false;
};

export const getEdgeVersion = () => {
  const raw = navigator.userAgent.match(/edg\/([0-9]+)\./i);

  return raw ? parseInt(raw[1], 10) : false;
};

export const showBrowserUpgradeBanner = () => {
  if (isChrome()) {
    return getBrowserShortVersion() < supportedBrowserVersions.CHROME;
  } else if (isSafari()) {
    return getBrowserShortVersion() < supportedBrowserVersions.SAFARI;
  } else if (isNewEdge()) {
    return getBrowserShortVersion() < supportedBrowserVersions.EDGE;
  } else if (isFirefox()) {
    return getBrowserShortVersion() < supportedBrowserVersions.FIREFOX;
  } else return false;
};

export const openSoundSettings = () => {
  if (isMac()) {
    open('x-apple.systempreferences:com.apple.Sound-Settings.extension');
  } else if (isWindows()) {
    open('ms-settings:sound');
  }
};

export const showGraphicAccelerationWarning = () => {
  return (
    isChrome() && /SwiftShader/i.test(JsMediaSDK_Instance.util?.getGpuInfo?.())
  );
};
