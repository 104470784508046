const DOM_KEY_CODE = {
  ESC: 27,
  ENTER: 13,
  TAB: 9,
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  CANCEL: 3,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  BACKSLASH: 220,
  BACKSAPCE: 8,
  DELETE: 46,
};

const WORKER_TYPE = {
  VIDEO_ENCODE: '0',
  VIDEO_DECODE: '1',
  AUDIO_ENCODE: '2',
  AUDIO_DECODE: '3',
  SHARING_ENCODE: '4',
  SHARING_DECODE: '5',
};

const A11Y_FOCUS_WALKER_POLICY = {
  NONE: -1,
  LIST_ITEM_POLICY: 0,
  LIST_POLICY: 1,
  TAB_POLICY: 2,
  LIST_ITEM_CHILD_NODE_POLICY: 3,
  NORMAL_NODE_POLICY: 4,
  PREV_TRIGGER_POLICY: 5,
  NEXT_TRIGGER_POLICY: 6,
  LIST_CHECKBOX_ITEM_POLICY: 7,
  TAB_TO_FIRST_CHILD_POLICY: 8,
  LIST_TREE_VIEW_POLICY: 9,
  PP_NEXT_CHILD_POLICY: 10,
  LIST_POLICY_2: 11,
  PP_NEXT_CHILD_POLICY_2: 12,
  NORMAL_NODE_POLICY_2: 14,
  UNFOCUS_ORDER_NODE_POLICY: 15,
  QA_CONTENT_LIST_POLICY: 102,
  QA_ANSWER: 103,
  REACTION_ALL_EMOJI_PICKER_NAVBAR_BUTTON_POLICY: 201,
  REACTION_ALL_EMOJI_PICKER_SEARCH_BOX_POLICY: 202,
  REACTION_ALL_EMOJI_PICKER_SKIN_TONE_POLICY: 203,
  REACTION_ALL_EMOJI_PICKER_SKIN_TONE_POP_UP_POLICY: 204,
  REACTION_ALL_EMOJI_PICKER_EMOJI_LIST_CONTAINER_POLICY: 205,
  REACTION_ALL_EMOJI_PICKER_EMOJI_POLICY: 206,
  REACTION_SIMPLE_EMOJI_PICKER_EMOJI_POLICY: 207,
  POLL_GUARD_DEFAULT_TAB: 301,
  POLL_BODY_LIST_ITEM: 302,
  POLL_SINGLE_CHOICE: 303,
  POLL_RANK_ORDER_CHOICE: 304,
  STATISTICS_TAB_POLICY: 305,
  DEFAULT_POLICY: 306,
  BACKGROUND_IMAGE_LIST_POLICY: 401,
  BACKGROUND_IMAGE_ITEM_POLICY: 402,
  RETURN_BACKGROUND_TAB_POLICY: 403,
  SKIN_TONE_LIST_POLICY: 404,
  SETTING_TAB_BAR_LIST_POLICY: 405,
  SETTING_TAB_BAR_NODE_POLICY: 406,
  BO_PARTICIPANTS_ITEM_POLICY: 501,
};

const PLATFORM_TYPE_CODE = {
  UNKNOWN: 0,
  WIN: 1,
  MAC: 2,
  PAD: 3,
  MOBILE: 4,
  CALL_IN: 5,
  LINUX: 6,
  WEBCLIENT: 7,
  CHROME: 8,
};

const GLOBAL_POPOVER_PATTERN_ENUM = {
  DEFAULT: 0,
  PATTERN1: 1,
  PATTERN2: 2,
};

const COMPOSITE_CHECKBOX_MASK_ENUM = {
  PATTERN1: 1,
};

const COMPOSITE_CHECKBOX_ALLY_BORDER_PATTERN_ENUM = {
  PATTERN1: 1,
};

const WC_LOCAL_EVT_ENUM = {
  BLUR: 0,
  FOCUS_OUT: 1,
};

const USER_ROLE_ENUM = {
  ATTENDEE: 0,
  HOST: 1,
  OWNER: 4,
  VIEWONLY: 8,
};

const RWG_USER_TYPE = Object.freeze({
  CLIENT: 9,
  PHONE: 5,
  H323: 15,
  RSGW: 54,
});

const RECORDING_STATUS = Object.freeze({
  STOP: 0,
  RECORDING: 1,
  PAUSE: 2,
  INIT: 3, // is starting record
});

const CMR_RECORDING_STATUS = Object.freeze({
  NONE: 0, // no ubr required, can't be set by client
  INIT: 1, // inviting MMR, can't be set by client
  START: 2,
  PAUSE: 3,
  STOP: 4,
});

const CONF_STATUS = Object.freeze({
  DEFAULT: 0,
  FREE_USER_30MIN_OUT_NOTIFY: 1,
  PAID_MEETING_START: 2,
  JBH_MEETING_START: 3,
  FREE_MEETING_START: 4,
});

const MINIMIZE_SIZE = {
  WIDTH: 240,
  HEIGHT: 135,
};

const PIP_SIZE = {
  WIDTH: 480,
  HEIGHT: 270,
};

const CONFIG_ALL = 'ALL';
const FAILOVER_REASON = {
  NORMAL_CASE: -1, // just like enter/leave BO, promote/depromote, admit/put in waiting room
  NO_HEARTBEAT: 0,
  NO_HEARTBEAT_M_CHANNEL: 1, // type = m
  NETWORK_CHANGE: 2,
  NOTIFY_UI_FAILOVER: 3,
  NOTIFY_UI_WMSC_FAILOVER: 3.1,
  NOTIFY_UI_WMSC_WSS_DISCONNECTED: 3.2,
  INVALID_PARAMETERS: 4,
  ON_ERROR: 5, // call by onError()
  ON_ERROR_M_CHANNEL: 6,
  DISCONNECT: 7, // unexpected disconnected by server, not normal case.
  DISCONNECT_M_CHANNEL: 8,
  DISCONNECT_XMPP: 9,
  REFRESH_PAGE: 10,
  NO_RWG_RESPONSE: 11,
  CONSOLE_CLOSED: 12, // closed using a command in devtools
  DEAD_IN_BACKGROUND: 13, // most occurred in ios
};

const MEETING_FAIL_REASON = {
  tryRWCError: 50001,
  refreshTokenFailed: 50002,
  noRWGAvailable: 50003,
};

const UNICODE = {
  SHIFT_KEY: '\u21e7',
  COMMAND_KEY: '\u2318',
  MULTIPLY: '\u00D7',
};

export {
  DOM_KEY_CODE,
  PLATFORM_TYPE_CODE,
  GLOBAL_POPOVER_PATTERN_ENUM,
  COMPOSITE_CHECKBOX_MASK_ENUM,
  COMPOSITE_CHECKBOX_ALLY_BORDER_PATTERN_ENUM,
  A11Y_FOCUS_WALKER_POLICY,
  WC_LOCAL_EVT_ENUM,
  USER_ROLE_ENUM,
  RWG_USER_TYPE,
  RECORDING_STATUS,
  CONF_STATUS,
  CMR_RECORDING_STATUS,
  WORKER_TYPE,
  MINIMIZE_SIZE,
  CONFIG_ALL,
  FAILOVER_REASON,
  UNICODE,
  MEETING_FAIL_REASON,
  PIP_SIZE,
};
