import * as socketTypes from '../../constants/ZoomSocketEventTypes';
import {
  CLIENT_CAPS_SUPPORT_VIDEO_SHARE,
  CLIENT_CAPS_SIDECAR_PHASE_2,
  CLIENT_CAPS_SUPPORT_ASL,
  CLIENT_CAPS_SUPPORT_BROADCAST_VOICE,
  CLIENT_CAPS_SEND_MEETING_TOKEN_SEPARATELY,
  CLIENT_CAPS_SUPPORT_PREVIEW,
  CLIENT_CAPS_WEB_CAMERA_ON,
  CLIENT_CAPS_GALLERY_VIEW_SORT_BY_TYPE,
  CLIENT_CAPS_IN_MEETING_WHITE_BOARD,
  CLIENT_CAPS_REQUEST_REMOTE_CONTROL,
  CLIENT_CAPS_SMART_SUMMARY,
  CLIENT_CAPS_SUPPORT_UNIQUE_ERROR_CODE,
  CLIENT_CAPS_WEBRTC_AUDIO_RTCP_TIME,
  CLIENT_CAPS_SUPPORT_SHARE_MULTI_STREAM,
  CLIENT_CAPS_SUPPORT_VIDEO_LTR,
  CLIENT_CAPS_SUPPORT_SIMULIVE_PHRASE_2,
  CLIENT_CAPS_MEETING_QUERY_NOTIFICATION,
  CLIENT_CAPS_SUPPORT_GRANT_LOCAL_LIVESTREAMING,
  // CLIENT_CAPS_SUPPORT_ANSWER_START_CMR_REQ,
  CLIENT_CAPS_SUPPORT_MEETING_QUERY,
  CLIENT_CAPS_SUPPORT_QUERY_REQUEST_ENABLE_FLOW,
  CLIENT_CAPS_SUPPORT_QUERY_REQUEST_START_FLOW,
  CLIENT_CAPS_SUPPORT_FAR_END_CAMERA_CONTROL,
  CLIENT_CAPS_SUPPORT_ANSWER_START_CMR_REQ,
  CLIENT_CAPS_SUPPORT_SEND_1080P_VIDEO,
  CLIENT_CAPS_SUPPORT_SEND_1080P_SHARE,
  CLIENT_CAPS_SUPPORT_LOCALRECORDING,
  CLIENT_CAPS_GRANT_LOCALRECORDING_PERMISSION,
  CLIENT_CAPS_WEBINAR_NO_NEED_TYPE_EMAIL,
} from '../constant';
import {
  isSinglePageFlowEnabled,
  isSupportGallerySortMode,
  isSupportWaitingRoomWithoutFailoverFlow,
  isSupportSimulivePhase2,
} from './index';
import {
  isSupportMultiView,
  isExternalControlledMode,
  isSupportBroadcastVoice,
  isSupportVideoShare,
  isSupportZoomRemoteControl,
  isSupportAudioBridgeAvsync,
  isSupportCameraPTZ,
  isSupportSendVideoFullHD,
  isSupportSendVideoShareFullHD,
} from '../util';
import meetingConfig from 'meetingConfig';
import { isInPreview } from '../../tasks/service';
import {
  isEnableVideo1080PSendFeature,
  isEnableVideoShare1080PSendFeature,
} from '../op-feature-option';
import meetingField from 'meetingField';
import { CLIENT_CAPS_EX_SUPPORT_WATTING_ROOM_ACCELERATE } from '../clientCapsEx';
import { isAudioBridge } from './audio-bridge-service';

export const sendLaunchParams = (params, sendFn) => {
  sendFn({
    evt: socketTypes.WS_CONF_LAUNCH_PARAMS_REQ,
    body: params,
  });
};

export const sendRejoinRequest = (sendFn) => {
  sendFn({ evt: socketTypes.WS_CONF_REJOIN_REQ });
};
function setCaps(caps, currentCap) {
  return caps | currentCap;
}

export const getClientCapsEx = () => {
  let capEx = 0;
  if (isSupportWaitingRoomWithoutFailoverFlow()) {
    capEx = setCaps(capEx, CLIENT_CAPS_EX_SUPPORT_WATTING_ROOM_ACCELERATE);
  }
  return capEx;
};
export const getClientCap = (isBo) => {
  let caps =
    CLIENT_CAPS_SIDECAR_PHASE_2 |
    CLIENT_CAPS_WEB_CAMERA_ON |
    CLIENT_CAPS_SEND_MEETING_TOKEN_SEPARATELY |
    CLIENT_CAPS_SUPPORT_UNIQUE_ERROR_CODE |
    CLIENT_CAPS_SMART_SUMMARY |
    CLIENT_CAPS_MEETING_QUERY_NOTIFICATION |
    CLIENT_CAPS_SUPPORT_MEETING_QUERY |
    CLIENT_CAPS_SUPPORT_QUERY_REQUEST_ENABLE_FLOW |
    CLIENT_CAPS_SUPPORT_QUERY_REQUEST_START_FLOW |
    CLIENT_CAPS_MEETING_QUERY_NOTIFICATION |
    CLIENT_CAPS_SUPPORT_GRANT_LOCAL_LIVESTREAMING |
    CLIENT_CAPS_SUPPORT_ANSWER_START_CMR_REQ |
    CLIENT_CAPS_SUPPORT_LOCALRECORDING |
    CLIENT_CAPS_GRANT_LOCALRECORDING_PERMISSION;
  // CLIENT_CAPS_SUPPORT_ANSWER_START_CMR_REQ; // [smart recording]add it next time
  if (isSupportZoomRemoteControl() && !isBo) {
    caps = caps | CLIENT_CAPS_REQUEST_REMOTE_CONTROL;
  }
  if (isSinglePageFlowEnabled() && !meetingConfig.isStart && isInPreview()) {
    caps = caps | CLIENT_CAPS_SUPPORT_PREVIEW;
  }
  if (!isExternalControlledMode()) {
    if (
      meetingConfig.meetingOptions.isWebclientInMeetingWhiteboardEnabled &&
      navigator.hardwareConcurrency > 2
    ) {
      caps = setCaps(caps, CLIENT_CAPS_IN_MEETING_WHITE_BOARD);
    }
    if (isSupportMultiView()) {
      caps = setCaps(caps, CLIENT_CAPS_SUPPORT_ASL);
    }
    if (isSupportCameraPTZ()) {
      caps = setCaps(caps, CLIENT_CAPS_SUPPORT_FAR_END_CAMERA_CONTROL);
    }
  }
  if (isSupportBroadcastVoice()) {
    caps = setCaps(caps, CLIENT_CAPS_SUPPORT_BROADCAST_VOICE);
  }
  if (isSupportVideoShare()) {
    caps = setCaps(caps, CLIENT_CAPS_SUPPORT_VIDEO_SHARE);
  }

  if (isSupportGallerySortMode()) {
    caps = setCaps(caps, CLIENT_CAPS_GALLERY_VIEW_SORT_BY_TYPE);
  }

  if (isAudioBridge() && isSupportAudioBridgeAvsync()) {
    caps = setCaps(caps, CLIENT_CAPS_WEBRTC_AUDIO_RTCP_TIME);
  }

  if (JsMediaSDK_Instance.util.isSupportShareMultiStream?.()) {
    caps = setCaps(caps, CLIENT_CAPS_SUPPORT_SHARE_MULTI_STREAM);
  }
  if (JsMediaSDK_Instance.util.isSupportVideoLTR?.()) {
    caps = setCaps(caps, CLIENT_CAPS_SUPPORT_VIDEO_LTR);
  }
  if (isSupportSimulivePhase2()) {
    caps = setCaps(caps, CLIENT_CAPS_SUPPORT_SIMULIVE_PHRASE_2);
  }
  if (isSupportSendVideoFullHD() && isEnableVideo1080PSendFeature()) {
    caps = setCaps(caps, CLIENT_CAPS_SUPPORT_SEND_1080P_VIDEO);
  }
  if (isSupportSendVideoShareFullHD() && isEnableVideoShare1080PSendFeature()) {
    caps = setCaps(caps, CLIENT_CAPS_SUPPORT_SEND_1080P_SHARE);
  }
  if (!meetingConfig.enableCustomEmailByUser && meetingField.emailVal === '') {
    caps = setCaps(caps, CLIENT_CAPS_WEBINAR_NO_NEED_TYPE_EMAIL);
  }
  return caps;
};
